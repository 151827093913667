<template>
  <div>
    <NavTitle class="mb16" :title="navTitle + '配置'">
      <template #right>
        <div class="flex-1 flex justify-end">
          <!-- <div class="flex items-center">
            <el-switch v-model="isDefault" active-color="#08C8BD"></el-switch>
            <span class="ml12 fs16">切换成默认配置</span>
          </div> -->
          <div class="flex items-center">
            <el-select v-model="curChannel" clearable placeholder="请选择渠道" @change="selectChange">
              <el-option
                v-for="item in channelList"
                :key="item.key"
                :label="item.value"
                :value="item.key">
              </el-option>
            </el-select>
            <el-select class="ml16" v-model="curApp" clearable placeholder="请选择应用" @change="selectChange">
              <el-option
                v-for="item in appList"
                :key="item.key"
                :label="item.value"
                :value="item.key">
              </el-option>
            </el-select>
          </div>
        </div>
      </template>
    </NavTitle>
    <el-row :gutter="20" class="pl20 pr20">
      <el-col :span="12" class="pointer mb16" v-for="(item, index) in configList" :key="index">
        <div class="config-item">
          <div>
            <div class="flex between-center">
              <div class="flex flex-1 items-center">
                <LoadImage class="avatar" :src="logo" v-for="(logo, i) in item.appLogo" :key="i"></LoadImage>
                <span class="name">{{item.configName}}</span>
              </div>
              <!-- <span class="tips tips-wran">渠道名称填写错误</span> -->
            </div>
            <div class="tag-list">
              <div class="tag" v-for="(tag, i) in item.tags" :key="i">{{tag}}</div>
            </div>
          </div>
          <div class="flex items-center mt16">
            <div class="btn-border-red mr10" @click="handleDelete(item)">
              <i class="el-icon-delete mr4"></i>
              删除配置
            </div>
            <el-button type="warning" @click="openConfig(true, item)">
              <i class="el-icon-edit"></i>
              编辑信息
            </el-button>
            <el-button type="info" @click="toConfigDetail(item)">
              <i class="el-icon-setting"></i>
              管理该配置
            </el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="pointer">
        <div class="btn-add" @click="openConfig()">
          <img src="@/assets/images/add-fill.png">
          <div>添加配置</div>
        </div>
      </el-col>
    </el-row>
    <!-- <el-empty v-else description="暂无相关数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty> -->

    <!-- 配置弹窗 -->
    <ConfigPopup ref="configPopupRef" @update="getConfigList"></ConfigPopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import LoadImage from '@/components/LoadImage'
import ConfigPopup from './components/ConfigPopup.vue'
import { dictListApi } from '@/api/opera.js'
import { resConfListApi, resProductConfManageApi } from '@/api/resConf.js'
import { mapState } from 'vuex'
export default {
  name: 'ConfigList',
  components: {
    NavTitle,
    LoadImage,
    ConfigPopup
  },
  data() {
    return {
      resType: '',
      curChannel: '',
      curApp: '',
      channelList: [],
      appList: [],
      isDefault: false,
      configList: []
    }
  },
  computed: {
    ...mapState(['isResourceGray']),
    navTitle() {
      const map = {
        'HOME_BANNER': '首页海报',
        'HOME_BUTTON': '首页金刚区',
        'HOME_TYPE_TAB': '首页分栏',
        'HOME_NAV_PIC': '首页卡片',
        'FIND_BANNER': '发现页海报',
        'FIND_BUTTON': '发现页金刚区',
        'FIND_TYPE_TAB': '发现页分栏',
        'FIND_NAV': '发现页卡片',
      }
      return map[this.resType] || ''
    }
  },
  created() {
    this.resType =  this.$route.query.resType || ''
    this.getChannelList()
    this.getAppList()
    this.getConfigList()
    this.$eventBus.$on('ResetConfigList', this.resetChannalApp)
  },
  activated() {
    this.resType =  this.$route.query.resType || ''
    this.getChannelList()
    this.getAppList()
    this.getConfigList()
  },
  beforeDestroy() {
    this.$eventBus.$off('ResetConfigList', this.resetChannalApp)
  },
  methods: {
    resetChannalApp() {
      this.curApp = ''
      this.curChannel = ''
    },
    selectChange(e) {
      console.log('drop改变', e, this.curChannel, this.curApp)
      this.getConfigList()
    },
    getChannelList() {
      dictListApi({
        code: 'channel',
        pageSize: 20,
        startPage: 0
      }).then(res => {
        console.log('渠道列表', res)
        this.channelList = res.data?.dataList[0]?.dictItem || []
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getAppList() {
      dictListApi({
        code: 'productId',
        pageSize: 20,
        startPage: 0
      }).then(res => {
        console.log('应用列表', res)
         this.appList = res.data?.dataList[0]?.dictItem || []
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getConfigList() {
      /**
       * resType
       * HOME_BANNER：首页banner，海报
       * HOME_BUTTON：首页导航按钮，金刚区
       * HOME_TYPE_TAB：首页分栏配置
       * HOME_NAV_PIC：首页卡片配置
       * FIND_BANNER：发现页banner，海报
       * FIND_BUTTON：发现页导航按钮，金刚区
       * FIND_TYPE_TAB：发现页分栏配置
       * FIND_NAV：发现页卡片配置
       */
      const formData = {
        'ch': this.curChannel,
        'grayConfig': this.isResourceGray,
        'productId': this.curApp,
        'resType': this.resType
      }
      console.log('formData', formData)
      resConfListApi(formData).then(res => {
        console.log('配置列表',res)
        this.configList = res.data.confLists || []
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    openConfig(isEdit = false, info) {
      this.$refs.configPopupRef.open({
        resType: this.resType,
        isEdit: isEdit,
        detail: info
      })
    },
    handleDelete(info) {
      this.$confirm('确认删除此配置吗？').then(action => {
        if(action == 'confirm') {
          const formData = {
            act: 3,
            confId: info.confId,
            resType: this.resType
          }
          console.log('formData', formData)
          resProductConfManageApi(formData).then(res => {
            this.$tips({message: res.msg, type: 'success'})
            this.getConfigList()
          }).catch(err => {
            this.$tips({message: err, type: 'error'})
          })
        }
      })
    },
    toConfigDetail(info) {
      let path = ''
      if(this.resType == 'HOME_BANNER' || this.resType == 'FIND_BANNER') {
        path = '/resource/posterList'
      }
      if(this.resType == 'HOME_BUTTON' || this.resType == 'FIND_BUTTON') {
        path = '/resource/navButtonList'
      }
      if(this.resType == 'HOME_TYPE_TAB' || this.resType == 'FIND_TYPE_TAB') {
        path = '/resource/columnList'
      }
      if(this.resType == 'HOME_NAV_PIC' || this.resType == 'FIND_NAV') {
        path = '/resource/navPic'
      }
      this.$router.push({
        path,
        query: {
          resType: this.resType,
          confId: info.confId,
          title: info.configName
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.config-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 16px 24px;
  min-height: 200px;
  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    margin-right: 4px;
  }
  .name {
    flex: 1;
    max-width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .tips {
    position: relative;
    font-size: 12px;
    &::before {
      content: '';
      position: absolute;
      left: -8px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 4px;
    }
  }
  .tips-wran {
    color: var(--theme-color-red);
    &::before {
      background-color: var(--theme-color-red);
    }
  }
  .tips-green {
    color: var(--theme-color);
    &::before {
      background-color: var(--theme-color);
    }
  }
  .tag-list {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin-top: 8px;
    .tag {
      height: 26px;
      line-height: 26px;
      padding: 0 8px;
      background-color: #F3F7F8;
      border-radius: 6px;
      margin: 0 8px 4px 0;
    }
  }
}
.btn-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
  font-size: 16px;
  color: var(--theme-color);
  background-color: #fff;
  img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
  }
}
</style>